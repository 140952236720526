/* eslint-disable sort-keys-fix/sort-keys-fix */

import type { Config } from 'tailwindcss';

export const config: Config = {
  content: [],
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/container-queries'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/nesting'),
    require('@tailwindcss/typography'),
  ],

  /**
   * @description We are overriding the built in colors, fonts, and font sizes
   * to match the design system. This allows us to very easily work within our
   * design system, and limit variation. This approach still allows for full
   * customization (exceptions), but we should try to avoid it (99% of the time).
   */
  theme: {
    extend: {
      /**
       * @external https://www.figma.com/file/MTgC7rM8M7MlbwpxSDPk5H/Base-library?node-id=1353%3A906&t=0xl0qZ96HOgIR9sE-1
       * @external https://tailwindcss.com/docs/customizing-colors
       * @external https://tailwindcss.com/docs/customizing-colors#arbitrary-values
       */
      colors: {
        brand: {
          darker: '#073a6e',
          dark: '#0d559e',
          DEFAULT: '#1470cc',
          light: '#3d97f2',
          lighter: '#e0f0ff',
        },

        /**
         * @description Sentiment colors
         */
        error: {
          darker: '#6b0c0b',
          dark: '#a81c19',
          DEFAULT: '#cf2c29',
          light: '#f54f4c',
          lighter: '#ffd7d6',
        },
        info: {
          darker: '#003459',
          dark: '#005999',
          DEFAULT: '#0a74bf',
          light: '#249bf0',
          lighter: '#cceaff',
        },
        success: {
          darker: '#004015',
          dark: '#0f662c',
          DEFAULT: '#0e8c38',
          light: '#2db259',
          lighter: '#c4f5d5',
        },
        warning: {
          darker: '#362800',
          dark: '#bf8f00',
          DEFAULT: '#ffc61a',
          light: '#ffe494',
          lighter: '#ffe494', // TODO: Consistent naming would be preferred
        },

        /**
         * @description Product colors, our base color palette
         */
        asphalt: {
          darker: '#1b2a36',
          dark: '#364a59',
          DEFAULT: '#4b6273',
          light: '#6d889c',
          lighter: '#dde5eb',
        },
        teal: {
          darker: '#0a3333',
          dark: '#155959',
          DEFAULT: '#008787',
          light: '#38a1a1',
          lighter: '#c2f2f2',
        },
        lime: {
          darker: '#1b3600',
          dark: '#365912',
          DEFAULT: '#9bde59',
          light: '#d4f5b3',
          lighter: '#d4f5b3', // TODO: consistent naming would be preferred
        },
        yellow: {
          darker: '#362800',
          dark: '#5e4800',
          DEFAULT: '#f2c200',
          light: '#ffe785',
          lighter: '#ffe785', // TODO: consistent naming would be preferred
        },
        orange: {
          darker: '#452000',
          dark: '#7a3900',
          DEFAULT: '#f27e18',
          light: '#ffa04d',
          lighter: '#ffddbf',
        },
        red: {
          darker: '#5e1304',
          dark: '#9e240b',
          DEFAULT: '#cc3314',
          light: '#f05637',
          lighter: '#ffd9d1',
        },
        violet: {
          darker: '#411259',
          dark: '#721f9c',
          DEFAULT: '#9130c2',
          light: '#b654e8',
          lighter: '#f1d6ff',
        },
        purple: {
          darker: '#241d73',
          dark: '#4031c4',
          DEFAULT: '#5747e5',
          light: '#7a6eff',
          lighter: '#e1deff',
        },

        /**
         * @description Gray scale colors, from darkest to lightest (1-9)
         * and including black, white, and transparent.
         */
        transparent: 'transparent',
        black: '#000',
        gray: {
          1: '#17181a',
          2: '#343638',
          3: '#4a4d4f',
          4: '#66686b',
          5: '#898c8f',
          6: '#a8abad',
          7: '#c5c7c9',
          8: '#e1e2e3',
          9: '#f2f4f5',
        },
        white: '#fff',
      },
    },
    fontSize: {
      '3xs': '10px',
      '2xs': '11px',
      xs: '12px',
      sm: '14px',
      base: '16px',
      lg: '18px',
      xl: '20px',
      '2xl': '22px',
      '3xl': '30px',
      '4xl': '36px',
      '5xl': '40px',
    },
    fontFamily: {
      figtree: 'Figtree',
      hellix: 'Hellix',
      inter: 'Inter',
    },

    /**
     * Hellix ~ is a "variable font" face which allows for more dynamic weights.
     * Inter ~ is a Google Font which we request all these font sizes for.
     */
    fontWeight: {
      '100': '100',
      '200': '200',
      '300': '300',
      '400': '400',
      '500': '500',
      '600': '600',
      '700': '700',
      '800': '800',
      '900': '900',

      // Text variant which maps to the default TailwindCSS weights
      thin: '100',
      extralight: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
  },
};
