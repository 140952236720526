import * as React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classnames from 'classnames';

export interface ModalProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  onClose: () => void;
  open?: boolean;
  unmount?: boolean;
}

/**
 * @name Modal
 * @description A modal component
 */
export const Modal = (props: ModalProps) => {
  const {
    children,
    /* This value is passed down to the Dialog component */
    className = '',
    onClose,
    open,
    unmount = true,
    ...rest
  } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <Transition.Root as={Fragment} show={open} unmount={unmount}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={onClose}
        unmount={unmount}
        {...rest}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          unmount={unmount}
        >
          <div className="bg-gray-5 fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              unmount={unmount}
            >
              <Dialog.Panel
                className={classnames(
                  'background-main relative transform overflow-visible rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm',
                  className,
                )}
              >
                <>{children}</>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
