import { hydrateRoot } from 'react-dom/client';
import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
// import { datadogInit } from '@shiftsmartinc/remix-telemetry';
// import { APP_ENV, APP_NAME, APP_VERSION } from '~/global/config';

// /**
//  * Initialize Datadog as early as possible, we may also want to initialize
//  * other external client side services here
//  */
// datadogInit({
//   APP_ENV,
//   APP_NAME,
//   APP_VERSION,
//   applicationId: 'TODO: update when we set this up...',
//   clientToken: 'TODO: update when we set this up...',
// });

/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/docs/en/main/file-conventions/entry.client
 */
startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/worker.js');
  });

  /**
   * Prevents the default mini-infobar or install dialog from appearing on
   * mobile, storing the original event so we can use it later in the users
   * journey.
   */
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    global.deferredPrompt = e;
  });
}
